import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Nav from "../nav"

import Container from "../ui/container"
import badge from "../../images/badge.svg"

const Hero = () => {
  const {
    wpPage: { header: hero },
  } = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 2 }) {
        header {
          text
          backgroundImage {
            sourceUrl
          }
        }
      }
    }
  `)

  const [greeting, setGreeting] = useState("")

  useEffect(() => {
    const greetings = ["moin", "servus", "gude", "hallo", "yooo"]
    const r = Math.floor(Math.random() * greetings.length)

    setGreeting(greetings[r])
  }, [])

  return (
    <div
      className="bg-no-repeat bg-left md:bg-center bg-cover h-screen relative grid grid-cols-1 place-items-center hero-grid-rows bg-gray-500 bg-blend-multiply"
      style={{ backgroundImage: `url(${hero.backgroundImage.sourceUrl})` }}
    >
      <div className="bg-black bg-opacity-40 py-4 md:py-8 w-full">
        <Container>
          <Nav />
        </Container>
      </div>
      <Container>
        <div className="text-center -mt-16 px-6">
          <h1 className="font-headline uppercase text-purple-500 text-5xl md:text-7xl lg:text-9xl mb-4">
            {greeting}!
          </h1>
          <div
            dangerouslySetInnerHTML={{ __html: hero.text }}
            className="text-lg md:text-xl text-white max-w-prose mx-auto mb-4"
          ></div>
          <Link to="/impressum" className="text-white border-b border-white">
            Impressum
          </Link>
          <img
            src={badge}
            height="80"
            className="absolute bottom-12 left-1/2 -ml-8 w-16 md:left-auto md:right-12 md:m-0 md:w-20 animate-spin-slow"
            aria-hidden="true"
            alt="Badge mit dem Text: Producing the freshest stuff since 2018"
          />
        </div>
      </Container>
    </div>
  )
}

export default Hero
